import * as React from "react";
import ReactFullpage from '@fullpage/react-fullpage'
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {createRef, useContext, useEffect, useRef, useState} from "react";
import Navigation from "../components/Navigation";
import { useMediaQuery } from 'react-responsive'
import Logo from "../components/Logo";
import { gsap, Expo, Power1, Quint } from "gsap";
import {RefObject} from "react";
import Cursor from "../components/Cursor";
import {COLOR_ENUM} from "../components/Helpers/Color";
import Breadcrumb from "../components/Breadcrumb";
import {off, on, trigger} from "../components/Events";
import {getPageTitle} from "../components/Helpers/Meta";
import Fade from "../transitions/Fade";
import {getID} from "../components/Helpers/Random";
import ScrollIndicator from "../components/ScrollIndicator";

export const ABOUT_US_QUERY = graphql`
  query AboutUsQuery($id: String!) {
    content: contentfulAboutUsPage(
      id: { eq: $id }
    ) {
      part1TextLeft {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      part1TextRight {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      part2TextLeft {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      part2TextRight {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      part3TextLeft {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      part3TextRight {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      
        mainVideo {
          file {
            contentType
            url
          }
        }
        
        mainVideoMob {
          file {
            contentType
            url
          }
        }
    
        kippenvelVideo {
          file {
            contentType
            url
          }
        }
        
        kippenvelVideoMob {
          file {
            contentType
            url
          }
        }
        
        buutvrijVideo {
          file {
            contentType
            url
          }
        }
        
        buutvrijVideoMob {
          file {
            contentType
            url
          }
        }
    }
  }
`;

interface AboutUsTemplateProps {
    data: any
}

class PoeticContentBlock extends React.Component {

    ref : RefObject<HTMLDivElement> = createRef();
    textRef: RefObject<HTMLDivElement> = createRef();
    audio: HTMLAudioElement;
    id: string;

    state = {
        playing: false
    }

    constructor(props) {
        super(props);

        this.id = (Math.random() + 1).toString(36).substring(7)
        this.audio =  typeof Audio !== "undefined" ? new Audio(this.props.audioUrl) : null;
        if (this.audio) {
            this.audio.ontimeupdate = ((event) => {
                for (let i = 0; i < this.props.audioCues.length; i++) {
                    if (this.audio.currentTime > this.props.audioCues[i]) {
                        gsap.timeline()
                            .to(`.subtitle-${this.id} .sub-${i}`, {
                                color: this.props.textHighlightColor,
                                // textDecoration: 'underline',
                                duration: 0.5
                            })
                    }
                }
            })
        }
    }

    play () {
        this.setState({
            playing: true
        });
        this.audio.play();
    }

    pause() {
        this.setState({
            playing: false
        });
        this.audio.pause();
    }

    playPause() {
        if (!this.state.playing) {
            this.play();
        }
        else {
            this.pause();
        }
    }


    animateIn({ position }) {

        gsap.killTweensOf(this.ref.current)
        gsap.timeline()
            .set(this.ref.current, {
                display: 'flex',
                opacity: 0,
                backgroundColor: this.props.secondaryColor,
                color: this.props.secondaryColor,
            }, 0)
            .to(this.ref.current, {
                opacity: 1,
                duration: 0.4,
                ease: Quint.easeOut
            }, position)
            .to(this.ref.current, {
                color: this.props.primaryColor,
                y: 0,
                duration: 0.4,
                ease: Quint.easeOut
            }, position + 0.1)

        gsap.killTweensOf(this.textRef.current)
        gsap.timeline()
            .set(this.textRef.current, {
                y: 50
            })
            .to(this.textRef.current, {
                y: 0,
                ease: Quint.easeOut
            }, position + 0.1)

        gsap.killTweensOf('.c-logo__svg');
        gsap.timeline()
            .set('.c-logo__svg', {
                fill: this.props.logoColorFrom
            })
            .to('.c-logo__svg',  {
                fill: this.props.logoColorTo
            }, position + 0.4)

        gsap.killTweensOf('.c-cursor__close');
        gsap.timeline()
            .set('.c-cursor__close', {
                fill: this.props.primaryColor
            })

    }

    animateOut({ position }) {
        this.pause();
        gsap.timeline()
            .to(this.ref.current, {
                opacity: 0,
                duration: 0.4,
                ease: Quint.easeOut,
                onComplete: () => {
                    gsap.to(this.ref.current, {
                        display: 'none'
                    })
                }
            }, position + 1)

        gsap.timeline()
            .set(this.textRef.current, {
                y: 0
            })
            .to(this.textRef.current, {
                y: 50,
                ease: Quint.easeOut
            }, position + 0.1)

        gsap.timeline()
            .set('.c-logo__svg', {
                fill: this.props.logoColorTo
            })
            .to('.c-logo__svg',  {
                fill: this.props.logoColorFrom
            }, position + 0.4)


    }

    render() {
        const {
            isTabletOrMobile,
            name,
            textLeft,
            textRight,
            primaryColor,
            secondaryColor,
            logoColorFrom,
            logoColorTo,
            textTitle
        } = this.props

        const realTextLeft = textLeft.childMarkdownRemark.rawMarkdownBody;
        const realTextRight = textRight.childMarkdownRemark.rawMarkdownBody;
        const splitTextLeft = realTextLeft.split(' ');
        const splitTextRight = realTextRight.split(' ');

        let textLeftJoined = `<span class="sub-0 title">${textTitle}</span> `;
        let j = 1;
        for (let i = 0; i < splitTextLeft.length; i++) {
            textLeftJoined += `<span class="sub-${j}">${splitTextLeft[i].replace("\n\n", "<br /><br/>")}</span> `;
            j++;
        }

        let textRightJoined = ``;
        for (let i = 0; i < splitTextRight.length; i++) {
            textRightJoined += `<span class="sub-${j}">${splitTextRight[i].replace("\n\n", "<br /><br/>")}</span> `;
            j++;
        }

        return (
            <>
                {isTabletOrMobile ? (
                    <div ref={this.ref}
                         className={"o-section o-layout--full-page c-about-us--content c-about-us__poetic-content mobile"}
                    >
                        <div className={`o-layout u-columns-24 c-about-us__margin-large subtitle-${this.id}`} ref={this.textRef}>
                                <div className={"o-layout u-colspan-24 u-columns-24"}>
                                    <div className={"u-colspan-2 u-colspan-6@desk"}/>
                                    <div className={"u-colspan-20 u-colspan-12@desk"}>
                                        <span className={"c-about-us--voice-link prevent-close"} onClick={() => {
                                            this.playPause()
                                            trigger('cursor:unclick');
                                        }}
                                              onMouseEnter={() => {
                                                  trigger('cursor:click');
                                              }}
                                              onMouseOut={() => {
                                                  trigger('cursor:unclick');
                                              }}>
                                            {!this.state.playing ? (<>&#9659; {name} draagt voor</>) : (<>Stop met beluisteren...</>)}
                                        </span>
                                    </div>
                                    <div className={"u-colspan-2 u-colspan-6@desk"}/>
                                    <div className={"u-colspan-2 u-colspan-6@desk"}/>
                                    <div className={"u-colspan-20 u-colspan-12@desk"} dangerouslySetInnerHTML={{
                                        __html: textLeftJoined
                                    }}/>
                                    <div className={"u-colspan-2 u-colspan-6@desk"}/>
                                </div>

                                <div className={"o-layout u-colspan-24 u-columns-24 c-about-us__content-spacer"}>
                                    <div className={"u-colspan-2 u-colspan-6@desk"}/>
                                    <div className={"u-colspan-20 u-colspan-12@desk"} dangerouslySetInnerHTML={{
                                        __html: textRightJoined
                                    }}/>
                                    <div className={"u-colspan-2 u-colspan-6@desk"}/>
                                </div>
                        </div>
                    </div>
                ) : (
                    <div ref={this.ref}
                         className={"o-section o-layout--full-page c-about-us--content c-about-us__poetic-content"}
                        >
                        <div className={"o-layout u-columns-24"}>
                            <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"}/>
                            <div className={"u-colspan-20 u-colspan-16@desk u-colspan-12@wall"}>
                                <div className={`o-layout o-layout--gap-large subtitle-${this.id}`} ref={this.textRef}>
                                    <div className={"u-colspan-12 "}>
                                        <span className={"c-about-us--voice-link prevent-close"} onClick={() => {
                                            this.playPause()
                                            trigger('cursor:unclick');
                                        }}
                                              onMouseEnter={() => {
                                                  trigger('cursor:click');
                                              }}
                                              onMouseOut={() => {
                                                  trigger('cursor:unclick');
                                              }}>
                                            {!this.state.playing ? (<>&#9659;</>) : (<>||</>)} {name} draagt voor
                                        </span>
                                    </div>
                                    <div className={`u-colspan-6 prevent-close`} dangerouslySetInnerHTML={{
                                        __html: textLeftJoined
                                    }}/>
                                    <div className={`u-colspan-6 prevent-close`} dangerouslySetInnerHTML={{
                                        __html: textRightJoined
                                    }}/>
                                </div>
                            </div>
                            <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"}/>
                        </div>
                    </div>
                )}
            </>
        )

    }
}

const AboutUsTemplate = ({data}: AboutUsTemplateProps) => {
    const {
        content: {
            part1TextLeft,
            part1TextRight,
            part2TextLeft,
            part2TextRight,
            part3TextLeft,
            part3TextRight,
            mainVideo,
            mainVideoMob,
            kippenvelVideo,
            kippenvelVideoMob,
            buutvrijVideo,
            buutvrijZoekFotos,
            buutvrijVideoMob
        }
    } = data;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })
    const [style, setStyle] = useState('#FF0032');
    // const [isBackgroundLight, setIsBackgroundIsLight] = useState(false);
    const [isPagePartsChanged, setIsPagePartsChanged] = useState(false);
    const fullpageRef = useRef();
    const [logoState, setLogoState] = useState(Logo.DEFAULT);
    const firstPoeticContentBlockRef = useRef(null);
    const secondPoeticContentBlockRef = useRef(null);
    const thirdPoeticContentBlockRef = useRef(null);
    const fixedContentRef = useRef(null);
    const showingContent = useRef(null);
    const isShowingContent = useRef(false);
    const fullpageApiRef = useRef(null);
    const audioCues = [
        [0,1.76,2.48,2.52,2.84,3.36,3.8,4.52,4.68,6.12,6.88,7.12,7.32,7.32,7.36,7.84,8.2,9.8,10,10.48,10.68,11.6,12.16,13.36,14.16,14.52,15.12,15.24,15.6,16.08,16.48,17.52,17.76,18.12,18.28,18.64,18.88,19.6,19.84,20.2,21,21.2,21.2,23.12,23.6,24.24,24.36,24.8,25.52,26.08,26.64,26.92,27.96,28.2,28.84,28.88,29.64,29.76,29.96,29.96,30.56,31.76,32.56,33.52,33.72,34.4,34.68,34.68,35.4,36.04,36.36,36.88,37.12,37.4,37.64,37.76,38.24,40.2,40.52,40.84,41.64,41.84,42.24,42.92,42.92,43.36,44.08,44.28,45.12,45.76,46,46.2,46.8,47.44,48.04,48.44,48.8,48.88,50.52,50.92,51.36,51.84,52.08,53.8,54,54.32,54.8,55.36,55.76,55.76,55.76,55.84,55.88,57.28,57.28,57.28,57.28,57.8,59.48,60.04,61,61.16,61.96,62.56,63.08,63.44,63.92,65.04,66.08,66.6,67.84,68.24,68.56,69.04,69.04,69.6,70.16,71.44,71.84,72.12,72.56,73.6,73.84,74.6,75.64,76.04,76.52,77.36,77.8,77.9],
        [0,3.08,3.32,3.8,4.4,4.72,4.76,5.88,6.68,7.12,7.76,7.88,8.36,8.84,9.72,10.16,11.2,12,12.12,13.28,14.36,14.88,15.6,16,16.48,16.92,17.8,17.8,17.84,17.84,18.8,19.48,20.8,21.84,23,25.04,25.64,27.88,29.24,30.92,31.52,31.96,32.52,33.04,34,34.68,34.68,34.72,36.12,37.6,38.32,38.72,39.24,39.64,39.92,39.92,40.88,41.32,41.36,42.4,43.32,43.56,44.2,44.64,45.48,46.16,46.6,46.64,47.52,48.16,48.68,49.72,49.72,50.52,52,52.68,53.08,53.08,53.08,53.12,54.16,54.56,55.36,55.8,57,57.04,58,58.24,58.76,59.8,60.24,61.16,62.56,63.68,65.12,66.36,66.72,67.4,68.08,70.16,71.68,72.48,72.92,73.88,74,74.52,75.4,75.56,76.48,77.08,77.68,77.68,77.88,78.56,78.92,79.8,80.36,81.08,82.36,84.6,85.04,85.04,86.16,87.04,87.6,87.92,88.36,89.52,89.68,90.36,90.84,91.72,92.2,93.56,94.68,94.84,95.48,95.92,96.88,97.24,97.72,98.32,99.08,100.04,100.92,102.6,104.16,104.4,105.36,105.8,106.64,107.56,108.48,109.88,110.68,110.68,111.64,111.68,112.08,113.32,115.44,115.92],
        [0,1.92,3.76,4.12,4.84,5.64,6.04,6.52,7,7.92,8.72,9.16,9.72,10.08,11.04,11.84,13.56,13.6,14.08,15.28,15.52,16.36,16.88,16.92,17.88,18.36,18.76,18.76,18.76,19.6,19.76,20.32,20.68,21.32,21.68,22.6,23.2,23.88,23.88,24.24,24.48,25,25.88,26.44,26.96,27.6,27.72,28.16,28.64,29.28,29.8,31.24,31.52,32,32.52,32.6,32.88,32.96,33.76,34.4,35.28,36.72,37.28,37.96,39.32,40.68,41.68,42.12,43,43.6,44.08,44.64,45.56,45.76,45.8,47.8,48.08,48.16,49.52,50.12,50.4,51.52,52,52,52.44,53.8,53.92,54.04,54.56,54.92,55.12,57.28,57.6,57.64,58.2,58.68,59.72,60.84,61.28,62.16,63.36,63.92,64.88,65.88,66.28,67.52,68.12,68.68,69.16,69.16,70.96,72,72.64,73,74.72,74.72,75.12,75.48,75.76,76.24,76.44,76.44,76.44,76.44,78.6,79.2,79.68,79.68,79.68,80.56,80.8,81.28,81.72,82.84,83.36,83.56,83.92,84.16,86.2,86.8,87.92,89,89.24,89.32,90.04,90.08,90.08,90.88,91.28,91.28,92.36,92.72,93.08,93.32,93.88,94.4,94.44,96.36,96.8,97.8,98.28,99.08,99.6,100.52,100.6,100.88,101.72,103.32,104.48,105,107.52,108.04,108.12,111.72,112.16]
         ]

    const audioUrls = [
        'https://assets.ctfassets.net/qbjsb08h8wnd/1opy5rhmWMZyZAIIzZErZl/4fc54688b82b36b3b266511698b97b19/noa.mp3',
        'https://assets.ctfassets.net/qbjsb08h8wnd/5Ewcu605ehOPagD2LofmHR/28f3cb190cae0f20e9ebae595faebb2b/liv.mp3',
        'https://assets.ctfassets.net/qbjsb08h8wnd/SxCGH9uLJArfGsYVwS1Ps/8f0311a843923762f5f24b13314c8800/mare.mp3'
    ]

    const backgroundColors = [
        '#FF0032',
        '#64ABFC',
        '#FC0099'
    ];

    const RebuildFullpage = () => {
        if (fullpageRef && fullpageRef.current && typeof fullpageRef.current !== 'undefined') {
            fullpageRef.current.reRender();
            setBackground(fullpageRef.current.getSlideCount());
        }
    }

    const setBackground = (index) => {
        if (isShowingContent.current) return

        let logoColorTo = COLOR_ENUM.BLACK;

        switch(index) {
            case 0:
                logoColorTo = COLOR_ENUM.WHITE;
                break;
            case 1:
                logoColorTo = COLOR_ENUM.GREEN;
                break;
            case 2:
                logoColorTo = COLOR_ENUM.YELLOW
                break;
            default:
                logoColorTo = COLOR_ENUM.BLACK;
        }

        gsap.timeline()
            .to('.c-logo__svg', {
                fill: logoColorTo,
                duration: 0.4
            })

        setStyle(backgroundColors[index]);
    }

    const onLeave = (origin, destination, direction) => {
        setBackground(destination.index);
    }

    useEffect(() => {
        // setStyle(backgroundColors[0]);
        setIsPagePartsChanged(true);

        if (!isTabletOrMobile && fullpageApiRef && fullpageApiRef.current) {
            fullpageApiRef.current.setAllowScrolling(isShowingContent.current);
        }
    }, [isTabletOrMobile]);

    const afterRender = () => {
        if (isPagePartsChanged) {
            setIsPagePartsChanged(false);
        }

        if (fullpageRef && fullpageRef.current && style !== backgroundColors[fullpageRef.current.getSlideCount()]) {
            setBackground(fullpageRef.current.getSlideCount());
        }
    }

    useEffect(() => {
        if (isPagePartsChanged) {
            RebuildFullpage();
        }
    }, [isPagePartsChanged])

    const showPoeticContent = (contentRef) => {
        gsap.killTweensOf(fixedContentRef.current)
        gsap.timeline()
            .set(fixedContentRef.current, {
                display: 'block',
                opacity: 1
            })

        trigger('cursor:state', Cursor.CLOSE);
        contentRef.current.animateIn({ position: 0.4 });
        showingContent.current = contentRef.current;
        isShowingContent.current = true;

        if (fullpageApiRef && fullpageApiRef.current) fullpageApiRef.current.setAllowScrolling(false);
    };

    const closePoeticContent = (e) => {
        const target = e.target;
        const parentTarget = target.parentNode;
        const shouldClose = (
            !target.classList.contains('prevent-close')
            && !parentTarget.classList.contains('prevent-close'))

        if (!shouldClose) return;

        gsap.killTweensOf(fixedContentRef.current)
        gsap.timeline()
            .set(fixedContentRef.current, {
                display: 'block',
                opacity: 1
            })
            .to(fixedContentRef.current, {
                display: 'none',
                opacity: 0,
            }, 0.3)

        trigger('cursor:state', Cursor.THUNDER);

        if (showingContent && showingContent.current)
            showingContent.current.animateOut({ position: 0 });

        isShowingContent.current = false;
        fullpageApiRef.current.setAllowScrolling(true);
    }

    const hidePage = () => {

        if (showingContent && showingContent.current)
            showingContent.current.animateOut({ position: 0 });

        isShowingContent.current = false;

        Fade.animate([
            { target: '.fullpage-wrapper', type: Fade.TYPE_OPACITY }
        ])
    };

    useEffect(() => {
        on('page:out', hidePage)

        return () => {
            off('page:out', hidePage)
        }
    }, [])

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setCounter(counter+1)
    }, [isTabletOrMobile])


    return (
        <>
            <Logo state={logoState} />
            <Helmet>
                <title>{getPageTitle({title: 'Over ons'})}</title>
                <style>
                    {`body { background-color: ${style}; }`}
                </style>
            </Helmet>
            <Navigation />
            <ScrollIndicator />
            <Breadcrumb text={"Over ons"} />

            <div className={"c-about-us__fixed-content o-layout--full-page"} ref={fixedContentRef} onClick={closePoeticContent}>
                <PoeticContentBlock
                    ref={firstPoeticContentBlockRef}
                    isTabletOrMobile={isTabletOrMobile}
                    textLeft={part1TextLeft}
                    textRight={part1TextRight}
                    primaryColor={COLOR_ENUM.RED}
                    secondaryColor={COLOR_ENUM.WHITE}
                    logoColorFrom={COLOR_ENUM.WHITE}
                    logoColorTo={COLOR_ENUM.BLACK}
                    audioCues={audioCues[0]}
                    audioUrl={audioUrls[0]}
                    textHighlightColor={COLOR_ENUM.BLUE}
                    textTitle={"Streetsmart/Booksmart."}
                    name={"Noa (11 jaar, dochter van Elles Thijssen)"} />

                <PoeticContentBlock
                    ref={secondPoeticContentBlockRef}
                    isTabletOrMobile={isTabletOrMobile}
                    textLeft={part2TextLeft}
                    textRight={part2TextRight}
                    primaryColor={COLOR_ENUM.BLUE}
                    secondaryColor={COLOR_ENUM.GREEN}
                    logoColorFrom={COLOR_ENUM.GREEN}
                    logoColorTo={COLOR_ENUM.BLUE}
                    audioCues={audioCues[1]}
                    audioUrl={audioUrls[1]}
                    textHighlightColor={COLOR_ENUM.RED}
                    textTitle={"Verder van de Buut af."}
                    name={"Liv (4 jaar, dochter van Bas Uytdenhouwen)"} />

                <PoeticContentBlock
                    ref={thirdPoeticContentBlockRef}
                    isTabletOrMobile={isTabletOrMobile}
                    textLeft={part3TextLeft}
                    textRight={part3TextRight}
                    primaryColor={COLOR_ENUM.RED}
                    secondaryColor={COLOR_ENUM.YELLOW}
                    logoColorFrom={COLOR_ENUM.YELLOW}
                    logoColorTo={COLOR_ENUM.PINK}
                    audioCues={audioCues[2]}
                    audioUrl={audioUrls[2]}
                    textHighlightColor={COLOR_ENUM.BLUE_LIGHT}
                    textTitle={"Kippenvel."}
                    name={"Mare (7 jaar, dochter van Iris Bloem)"} />
            </div>
            <ReactFullpage
                navigation
                sectionSelector={".o-section"}
                onLeave={onLeave.bind(this)}
                licenseKey={"42E14A84-64D94EA5-B272F11A-DCFD7C5F"}
                ref={fullpageRef}
                afterRender={afterRender.bind(this)}
                verticalCentered={false}
                render={({state, fullpageApi}) => {
                    fullpageApiRef.current = fullpageApi;
                    return (
                        <ReactFullpage.Wrapper>
                            <div className={"o-section o-layout--full-page c-about-us--hero"} >
                                <div className={"c-about-us--hero--clickable-area"} onClick={() => {
                                    showPoeticContent(firstPoeticContentBlockRef)
                                }}
                                     onMouseEnter={() => {
                                         trigger('cursor:click');
                                     }}
                                     onMouseOut={() => {
                                         trigger('cursor:unclick');
                                     }} />


                                {isTabletOrMobile ? (
                                        <video muted={true}
                                               autoPlay={true}
                                               loop={true}
                                               playsInline={true}
                                               data-autoplay
                                               className={"o-object-fit--cover"}
                                               key={`vid-${getID()}-${counter}`}
                                               placeholder={"/images/placeholder_booksmart_m.jpg"}>
                                            <source src={mainVideoMob.file.url} type={mainVideoMob.file.contentType} />
                                        </video>
                                ) : (
                                        <video muted={true} autoPlay={true} loop={true} playsInline={true} data-autoplay
                                               className={"o-object-fit--cover"} key={`vid-${getID()}-${counter}`}
                                               placeholder={"/images/placeholder_booksmart_d.jpg"}>
                                            <source src={mainVideo.file.url} type={mainVideo.file.contentType} />
                                        </video>
                                )}
                            </div>
                            <div className={"o-section o-layout--full-page c-about-us--search"}>
                                <div className={"c-about-us--search--clickable-area"} onClick={() => {
                                    showPoeticContent(secondPoeticContentBlockRef)
                                }}
                                     onMouseEnter={() => {
                                         trigger('cursor:click');
                                     }}
                                     onMouseOut={() => {
                                         trigger('cursor:unclick');
                                     }} />
                                <div className={"c-about-us--photo o-layout--full-page"} key={`vid-${getID()}-${counter}`}>
                                    {isTabletOrMobile ? (
                                        <video muted={true} autoPlay={true} loop={true} playsInline={true} data-autoplay
                                               className={"c-about-us--search-photo-middle"}
                                               key={`vid-${getID()}-${counter}`}
                                               placeholder={"/images/placeholder_buutvrij_m.jpg"}>
                                            <source src={buutvrijVideoMob.file.url} type={buutvrijVideoMob.file.contentType} />
                                        </video>
                                        ): (
                                        <video muted={true} autoPlay={true} loop={true} playsInline={true} data-autoplay
                                               className={"c-about-us--search-photo-middle"}
                                               key={`vid-${getID()}-${counter}`}
                                               placeholder={"/images/placeholder_buutvrij_m.jpg"}>
                                            <source src={buutvrijVideo.file.url} type={buutvrijVideo.file.contentType} />
                                        </video>
                                    )}
                                </div>
                                <div className={"c-about-us--search-text-frame o-layout--full-page"}>
                                    <div className={"c-about-us--search-text u-font-family--secondary u-font-weight--semi-bold"}>
                                        <div className={"c-about-us--search-text-container"}>
                                            <svg viewBox="0 0 1201 531" className={"u-hidden@until-lap"}>
                                                <g fontSize="220" fontWeight="500" spacing="197" textDecoration="underline" fill="#7EFF00">
                                                    <g transform="translate(-353.000000, -289.000000)" fill="#7EFF00">
                                                        <text transform="translate(926.000000, 591.000000) rotate(90.000000) translate(-926.000000, -591.000000) ">
                                                            <tspan x="624" y="145">Verd</tspan>
                                                            <tspan x="624" y="342">er </tspan>
                                                            <tspan x="624" y="539">van </tspan>
                                                            <tspan x="624" y="736">de </tspan>
                                                            <tspan x="624" y="933">buut </tspan>
                                                            <tspan x="624" y="1130">af</tspan>
                                                        </text>
                                                    </g>
                                                </g>
                                            </svg>
                                            <svg viewBox="0 0 269 328" className={"u-hidden@lap"}>
                                                <g fontSize="90" fontWeight="500" spacing="90" textDecoration="underline" fill="#7EFF00">
                                                    <g transform="translate(-49.000000, -137.000000)" fill="#7EFF00">
                                                        <text transform="translate(149.000000, 333.500000) rotate(90.000000) translate(-149.000000, -333.500000) ">
                                                            <tspan x="-47" y="241">Verder </tspan>
                                                            <tspan x="-47" y="331">van de </tspan>
                                                            <tspan x="-47" y="421">buut af</tspan>
                                                        </text>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"o-section o-layout--full-page c-about-us--chicken-skin"}>
                                <div className={"c-about-us--chicken-skin--clickable-area"} onClick={() => {
                                    showPoeticContent(thirdPoeticContentBlockRef)
                                }}
                                     onMouseEnter={() => {
                                         trigger('cursor:click');
                                     }}
                                     onMouseOut={() => {
                                         trigger('cursor:unclick');
                                     }} />

                                {isTabletOrMobile ? (
                                        <video muted={true} autoPlay={true} loop={true} playsInline={true} data-autoplay
                                               className={"o-object-fit--cover u-visually-hidden@lap"}
                                               key={`vid-${getID()}-${counter}`}
                                               placeholder={"/images/placeholder_kippenvel_m.jpg"}>
                                            <source src={kippenvelVideoMob.file.url}
                                                    type={kippenvelVideoMob.file.contentType}/>
                                        </video>
                                ) : (
                                        <video muted={true} autoPlay={true} loop={true} playsInline={true} data-autoplay
                                               className={"o-object-fit--fill u-visually-hidden@until-lap"}
                                               key={`vid-${getID()}-${counter}`}
                                               placeholder={"/images/placeholder_kippenvel_d.jpg"}>
                                            <source data-src={kippenvelVideo.file.url} type={kippenvelVideo.file.contentType}/>
                                        </video>
                                )}
                            </div>
                        </ReactFullpage.Wrapper>
                    )
                }}
                />
        </>
    )
};

export default AboutUsTemplate;
